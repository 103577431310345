<template>
  <div class="ac-page">
    <div class="step-tabs flex-center">
      <!-- <div class="tab" :class="{'active': step == 1}">
        <div class="dot flex-center"></div>
        <div class="label">输入原始手机号</div>
      </div> -->
      <div class="tab active">
        <div class="dot flex-center"></div>
        <div class="label">绑定新的手机号</div>
      </div>
      
    </div>
    <div class="tips-label">
      更换后，可直接使用此手机号登录，同时账户中存在的服务预约工单信息也会同步到新的账号中
    </div>
    <!-- <div class="step-box">
      <div class="step-title">原始手机号</div>
      <div class="form">
        <div class="form-item">
          <div class="form-input">
            <input type="text" placeholder="请输入原始手机号" v-model="oldMobile" maxlength="11">
          </div>
        </div>
        <div class="form-item" style="visibility: hidden;">
          
        </div>
        <van-button type="info" class="form-btn flex-center" 
          :class="{ 'disabled' : (oldMobile == '' )}"
          @click="onNext">
          <span>下一步</span>
        </van-button>
      </div>
    </div> -->
    <div class="step-box">
      <div class="step-title">新的手机号</div>
      <div class="form">
        <div class="form-item">
          <!-- <div class="form-label">+86</div> -->
          <div class="form-input">
            <input type="text" placeholder="请输入新的手机号" v-model="newMobile" maxlength="11">
          </div>
        </div>
        <div class="form-item">
          <div class="form-input">
            <input type="text" placeholder="请输入短信验证码" v-model="captcha" maxlength="6">
            <div class="captcha" @click="getCaptcha">
              <span>{{ isCountDown ? '重新获取(' + count + 's)' : '获取验证码' }}</span>
            </div>
          </div>
        </div>
        <van-button type="info" class="form-btn flex-center" 
          :class="{ 'disabled' : (newMobile == '' || captcha == '' )}"
          :loading="loading"
          @click="onSubmit">
          <span>更换手机</span>
        </van-button>
      </div>
    </div>
      
  </div>
  
</template>

<script>
  import { Button, Notify, Toast, Dialog } from 'vant'
  import { isPhoneNumber } from '@/utils/common'
  import { getPhoneCaptcha, bindPhoneNumber } from '@/api/user'
  export default {
    components: {
      [Button.name]: Button
    },
    data() {
      return {
        step: 1,
        isCountDown: false,
        oldMobile: '',
        newMobile: '',
        loading: false,
        captchaLoading: false,
        count: 0,
        timer: null,
      }
    },
    methods: {
      // onNext() {
      //   if (this.oldMobile == '') {
      //     Notify('请输入手机号')
      //     return
      //   }
      //   if (!isPhoneNumber(this.oldMobile)) {
      //     Notify('手机号格式不正确')
      //     return
      //   }
      //   this.step = 2
      // },
      getCaptcha() {
        if (this.isCountDown === true || this.captchaLoading === true) {
          return
        }
        if (this.newMobile == '') {
          Notify('请输入手机号')
          return
        }
        if (!isPhoneNumber(this.newMobile)) {
          Notify('手机号格式不正确')
          return
        }
        this.captchaLoading = true
        getPhoneCaptcha({mobile: this.newMobile}).then(() => {
          Toast('验证码发送成功')
          this.count = 60
          this.isCountDown = true
          this.captchaLoading = false
          if (this.timer) {
            clearInterval(this.timer)
          }
          this.timer = setInterval(() => {
            if (this.count === 0) {
              clearInterval(this.timer)
              this.isCountDown = false
            }
            this.count --
          }, 1000)
        }).catch(() => {
          this.captchaLoading = false
        })
        // 发送验证码
      },
      onSubmit() {
        if (this.newMobile.trim() == '') {
          Toast('请输入手机号')
          return
        }
        if (!isPhoneNumber(this.newMobile)) {
          Toast('手机号格式不正确')
          return
        }
        if (this.captcha.trim() == '') {
          Toast('请输入短信验证码')
          return
        }
        const toast = Toast.loading({
          message: '处理中...',
          forbidClick: true,
        })
        const params = {
          mobile: this.newMobile,
          code: this.captcha
        }
        bindPhoneNumber(params).then(() => {
          toast.clear()
          Dialog.alert({
            title: '绑定成功',
            confirmButtonColor: '#186AFF'
          }).then(() => {
            this.$router.go(-1)
          });
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>